import React from "react";

import ButtonSolid from "../Button/ButtonSolid";
import largePlus from "../../images/0.0 Repeating Modules/Plus icon large.svg";

const CTA = ({ heading, headingLevel, paddingTop, location, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <div className="container">
      <div className={`pb-16 md:pb-22 ${paddingTop && "pt-20 md:pt-32"}`}>
        <section
          className={`relative rounded-tl-6xl rounded-br-6xl bg-gradient-to-r from-secondary-500 to-primary-500 px-8 pt-16 pb-16 md:pt-20 md:pb-18 ${
            className || ""
          }`}
        >
          <img
            src={largePlus}
            alt="Large plus sign"
            width={78}
            className="absolute top-[-27px] right-[-27px] z-10 w-[54px] md:top-[-40px] md:right-[-39px] md:w-[78px]"
          />
          <div className="absolute inset-0 m-px rounded-tl-6xl rounded-br-6xl bg-[#1D1D1D]" />
          <header className="relative text-center">
            <HeadingTag className="heading-three mb-14 md:mb-8">
              {heading || [
                "Get On The Right Path. ",
                <span className="text-typography-body">
                  Everything else gets easier when you have a dedicated team of
                  top {location && location} CPAs on your side.
                </span>,
              ]}
            </HeadingTag>

            <div className="flex items-center justify-center space-x-4">
              <ButtonSolid modal="modal-contact" text="Talk To An Advisor" />
            </div>
          </header>
        </section>
      </div>
    </div>
  );
};

export default CTA;

import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Business CPA Services | DC, MD & VA | DeBlanc + Murphy"
        description="Are your financials overwhelming you? Get professional business CPA services from DeBlanc + Murphy, a top CPA firm serving the greater Washington, DC area."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="relative pb-10 pt-18 md:pt-16 md:pb-16">
        <div className="container">
          <header className="max-w-[720px]">
            <h1>Business CPA Services</h1>
            <p className="mb-0 md:text-xl">
            Feeling overwhelmed? We’re a full-service CPA firm serving Waldorf, 
            Maryland and the greater Washington, DC area. 
            We can help you manage all aspects of your business’ financials.
            </p>
          </header>
        </div>
      </section>

      <section className="bg-primary-100 pt-20 pb-18 md:pt-18">
        <div className="container">
          <div className="grid gap-y-14 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
            <div>
              <Link
                to="/business-tax-preparation-services/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/2.0 Business Hub/1.0 Business Tax Preparation.jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      Business Tax Preparation
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      Get peace of mind knowing your taxes have been prepared
                      correctly and professionally.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <Link
                to="/business-tax-planning-services/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/2.0 Business Hub/1.1 Business Tax Planning.jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      Business Tax Planning
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      Tax planning is the best way to reduce tax liabilities for
                      any business. We’ll help you identify tax-saving
                      strategies.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <Link
                to="/outsourced-accounting-bookkeeping-services/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/2.0 Business Hub/1.3 Outsourced Accounting _ Bookkeeping.jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      Accounting & Bookkeeping
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      Let us handle your day-to-day bookkeeping and accounting
                      tasks, including payroll and financial statements.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <Link
                to="/business-consulting-services/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/2.0 Business Hub/1.4 Business Consulting .jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      Business Consulting
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      When you need advice, lean on our experience in strategic
                      planning, feasibility studies, information systems
                      evaluation, outsourcing, and more.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <Link
                to="/business-valuation-services/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/2.0 Business Hub/1.5 Business Valuation.jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      Business Valuation
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      Find out what your business is worth with a valuation that
                      determines its economic value.
                    </p>
                  </div>
                </div>
              </Link>
            </div> 

            <div>
              <Link
                to="/tax-audit-attestation-services/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/2.0 Business Hub/1.6 Audit & Attestation Services.jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      Audit & Attestation Services
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      We’ll audit your books to see if your processes need to be
                      improved. We can also look for evidence of criminal
                      behavior.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <Link
                to="/irs-representation-services/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/2.0 Business Hub/1.7 IRS Representation.jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      IRS Representation
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      Are you worried about the IRS? Don’t panic. As your
                      representative, we can handle all communications with
                      them.
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <CallToAction paddingTop={true} />
    </Layout>
  );
};

export default Page;
